/**
 * @typedef {('beforebegin'|'afterbegin'|'beforeend'|'afterend')} insertAdjacentHTMLPosition
 */

/**
 * Manipulates the DOM to provide a toggle for showing and hiding the main navigation pane
 *
 * @param attachButtonTo {string} - A selector string to identify which unique node to place a toggle switch into
 * @param buttonPosition {insertAdjacentHTMLPosition} - Where in relation to the node to insert the toggle <button>
 * @param navigationContainer {string} - A selector string to identify the unique node whose visibility will be toggled by this
 * @param subNavSelector {string} - A selector string to identify any nodes that are sub-navigation elements
 */
function doMobileNavigation(
	attachButtonTo,
	buttonPosition,
	navigationContainer,
	subNavSelector
) {
	const html      = document.querySelector('html');
	const theMenu   = document.querySelector(navigationContainer);
	const subNavs   = theMenu.querySelectorAll(subNavSelector);
	const templateToggleNav = `
		<button id='mainNavToggle' aria-label="Open Main Navigation" role="button">
			Menu
		</button>
	`;

	// initialise everything
		html.dataset.sidebarNavigation = 'closed';

		document.querySelector(attachButtonTo)
			.insertAdjacentHTML(
				buttonPosition,
				templateToggleNav
		);

	// Now the button's in the DOM we can grab it
		const navToggle = document.querySelector('#mainNavToggle');

	// Accessibility enhancements for the dynamic toggling control
	// add ARIA labels to things that need them
		theMenu.setAttribute("aria-labelledby", "mainNavToggle");

	// handle clicks on the menu toggle button to show/hide the main panel
		navToggle.addEventListener("click",(e) => {
			let currentNavStatus = html.dataset.sidebarNavigation;

			if (currentNavStatus == 'open') {
				// is open now, so the click is to close it
				navToggle.setAttribute("aria-expanded", false); // applies new value
				navToggle.setAttribute('aria-label',"Open Main Navigation");
				navToggle.textContent = "Menu";
				if(html.querySelector('lang') == 'cy'){
					navToggle.textContent = "Dewislen";
				} else {
					navToggle.textContent = "Menu";
				}
				html.dataset.sidebarNavigation = "closed";

				// Also close any open sub-navs

				subNavs.forEach(subNav => {
					subNav.dataset.submenuStatus = "closed";
					subNav.setAttribute("aria-expanded", false);
				});
			} else {
				// is closed now, so the click is to open it
				navToggle.setAttribute("aria-expanded", true); // applies new value
				navToggle.setAttribute('aria-label',"Close Main Navigation");
				if(html.querySelector('lang') == 'cy'){
					navToggle.textContent = "Cau";
				} else {
					navToggle.textContent = "Close";
				}
				html.dataset.sidebarNavigation = "open";
			}
		});
}

/**
 * Handles DOM interactions for sub-menu display (this is independent of the mobile nav)
 *
 * @param navigationContainer {string} - A selector string to identify the main menu within which the sub-menus live
 * @param subNavSelector {string} - A selector string to identify any nodes that are sub-navigation elements
 */
function handleSubNavs(
	navigationContainer,
	subNavSelector
) {
	const theMenu   = document.querySelector(navigationContainer);
	const subNavs = theMenu.querySelectorAll(subNavSelector);

	// Accessibility enhancements for the dynamic toggling control
	// add ARIA labels to things that need them
		subNavs.forEach(subNav => {
			subNav.setAttribute('aria-haspopup', true);
			subNav.setAttribute('aria-expanded', false);
		});

	// Handle the sub-nav stuff
		subNavs.forEach(subNav => {
			let subNavToggle = subNav.querySelector(':scope > .groupAnchors > .toggleSubmenu');

			// add in a "back" button for each
				let subList = subNav.querySelector(':scope > ul');
				subList.insertAdjacentHTML("afterbegin", `<li class="js-back"><a href="#">Close Pane</a></li>`);

			// listen for clicks on the subNavToggle
				subNavToggle.addEventListener("click", function(e) {
					e.preventDefault();
					let clickedSubNavUl = e.currentTarget;

					// whatever is happening on this subNav, we want to hide all other subNavs UNLESS the other "sub nav" in question is actually a parent of the *current* nav (nested navs are fun)
						subNavs.forEach(thisSubNav => {
							if (! thisSubNav.contains(clickedSubNavUl)) {
								thisSubNav.dataset.submenuStatus = 'closed';
								subNav.setAttribute("aria-expanded", "false");
							}
						});

					// now, do we need to do anything with this particular subNav?
						let subNavIsOpen    = subNav.dataset.submenuStatus == 'open'; // returns true|false
						let newSubNavStatus = !subNavIsOpen; // inverts the value

						subNav.dataset.submenuStatus = newSubNavStatus ? 'open' : 'closed'; // update the data attribute
						subNav.setAttribute("aria-expanded", newSubNavStatus); // update the aria attribute

						// Ok, and does the nav we just closed itself have subNavs, which we ALSO need to close?
						subNav.querySelectorAll(':scope [data-submenu-status="open"]').forEach(another => {
							another.dataset.submenuStatus = "closed";
							another.setAttribute("aria-expanded", false);
						});

					// listen for clicks on the back link
						let backButton = subNav.querySelector(':scope .js-back a');
						backButton.addEventListener('click', function(e){
							e.preventDefault();

							let parent = e.currentTarget.closest('[data-submenu-status]');
							parent.dataset.submenuStatus = 'closed';
							parent.setAttribute("aria-expanded", "false");
						});
				});
		});
}

/**
 * Reverts changes and behaviours created by the `doMobileNavigation()` function
 */
function undoMobileNavigation(
	attachButtonTo,
	buttonPosition,
	navigationContainer,
	subNavSelector
) {
	const html      = document.querySelector('html');
	const theMenu   = document.querySelector(navigationContainer);
	const subNavs   = theMenu.querySelectorAll(subNavSelector);

	html.removeAttribute('data-sidebar-navigation');

	let navMenuToggle = document.querySelector('#mainNavToggle');
	if( navMenuToggle ) {
		document.querySelector('html').classList.remove("nav-active");
		document.querySelector('#mainNavToggle').remove();
		theMenu.removeAttribute('aria-labelledby');
	}
}

let screenWidth = window.matchMedia('(max-width: 1023px)');

// initial load
if( screenWidth.matches ) {
	doMobileNavigation(
		'.siteTitle',
		'afterend',
		'.nav_main',
		'[data-submenu-status]'
	);
}
else {
	undoMobileNavigation(
		'.siteTitle',
		'afterend',
		'.nav_main',
		'[data-submenu-status]'
	);
}

handleSubNavs('.nav_main','[data-submenu-status]');

// watch for changes in window size
screenWidth.addEventListener("change", (e) => {
	if( e.matches ) {
		doMobileNavigation(
			'.siteTitle',
			'afterend',
			'.nav_main',
			'[data-submenu-status]'
		);
	}
	else {
		undoMobileNavigation(
			'.siteTitle',
			'afterend',
			'.nav_main',
			'[data-submenu-status]'
		);
	}
});


/* Watch for clicks outside the nav panel, and dismiss all navs as a results */
	function dismissAllNav() {
		/* If the side nav is open and we've clicked off it, just simulate a click on the toggle */
		if( document.querySelector('html').dataset.sidebarNavigation == 'open' ) {
			document.querySelector('#mainNavToggle').click();
		}

		/* Close all other navs too */
		const subNavs = document.querySelectorAll('[data-submenu-status]');
		subNavs.forEach(nav => {
			nav.dataset.submenuStatus = "closed";
		});
	}

	// watch for nav dismissals
	const mainNavigationWrapper = document.querySelector('.nav_main');
	document.addEventListener('click', function (event) {
		if (
			! mainNavigationWrapper.contains(event.target)
			&&
			event.target.getAttribute('id') != 'mainNavToggle'
		) {
			dismissAllNav();
		}
	});
